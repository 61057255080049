import React, { useEffect, useState } from "react";
import axios from "axios";
import CustomizedAccordions from "./CustomizedAccordions";
import { Alert, Box, LinearProgress, Typography } from "@mui/material";

const SerieACalendar = () => {
  const [results, setResults] = useState({});
  const [competitions, setCompetitions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // const currentPath()
    // console.log();

    fetchRealData();
  }, []);

  async function fetchRealData() {
    setError(null);

    axios
      .get("/api/calendar")
      .then((response) => {
        const data = response.data;

        setResults(data.results);
        setCompetitions(data.competitions);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Errore nella richiesta API:", error);
        setLoading(false);
        setError("Errore nella richiesta");
      });
  }

  console.log("results", results);
  console.log("competitions", competitions);

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        {"Prossime Giornate"}
      </Typography>

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      {error && (
        <Box sx={{ width: "100%" }}>
          <Alert variant="outlined" severity="error">
            {error}
          </Alert>
        </Box>
      )}

      {competitions.length > 0 && (
        <CustomizedAccordions
          competitionState={competitions.map((ele) => ele.code)}
          competitions={competitions}
          allMatch={results}
        />
      )}
    </div>
  );
};

export default SerieACalendar;
