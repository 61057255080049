import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import BasicTable from "./BasicTable";

export default function CustomizedAccordions({
  competitionState,
  competitions,
  allMatch,
}) {
  const [expanded, setExpanded] = React.useState(competitionState || []);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(
      newExpanded
        ? [...expanded, panel]
        : expanded.filter((ele) => ele !== panel)
    );
  };

  return (
    <div>
      {competitions.map(({ code, label }) => {
        return (
          <Accordion
            expanded={expanded.includes(code)}
            onChange={handleChange(code)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "60%", flexShrink: 0 }}>
                {label}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                {`Giornata ${allMatch[label]?.nextRoundMatches?.[0]?.season?.currentMatchday}`}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <BasicTable
                rows={allMatch[label].nextRoundMatches}
                previousRoundResults={allMatch[label].previousRoundResults}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
