import { Box } from "@mui/material";
import SerieACalendar from "./components/SerieACalendar";

function App() {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <SerieACalendar />
    </Box>
  );
}

export default App;
