import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Stack, Typography } from "@mui/material";

export default function BasicTable({ rows, previousRoundResults }) {
  const getDateMatch = (utcDateString) => {
    try {
      var utcDate = new Date(utcDateString);
      var day = utcDate.getUTCDate();
      var month = utcDate.getUTCMonth() + 1;

      var dateString =
        (day < 10 ? "0" : "") + day + "-" + (month < 10 ? "0" : "") + month;

      return dateString;
    } catch (err) {
      console.error(err);
      return "";
    }
  };
  const getTimeMatch = (utcDateString) => {
    try {
      var utcDate = new Date(utcDateString);

      var hours = utcDate.getUTCHours();
      var minutes = utcDate.getUTCMinutes();
      var timeString =
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes;

      return timeString;
    } catch (err) {
      console.error(err);
      return "";
    }
  };

  const calculateMethod1 = (aID, bID) => {
    const aPrev = previousRoundResults.find(
      (ele) => ele.homeTeam.id === aID || ele.awayTeam.id === aID
    );
    const bPrev = previousRoundResults.find(
      (ele) => ele.awayTeam.id === bID || ele.homeTeam.id === bID
    );

    if (aPrev && bPrev) {
      const scoreA = aPrev.score; //milan
      const scoreB = bPrev.score; // roma

      //milan precedente a primo tempo ha fatto 1-0 o 0-1
      const aScorePrevCheck =
        (scoreA.halfTime.home === 1 && scoreA.halfTime.away === 0) ||
        (scoreA.halfTime.home === 0 && scoreA.halfTime.away === 1);

      //roma precedente a primo tempo ha fatto 1-0 o 0-1
      const bScorePrevCheck =
        (scoreB.halfTime.home === 1 && scoreB.halfTime.away === 0) ||
        (scoreB.halfTime.home === 0 && scoreB.halfTime.away === 1);

      //roma precedente a primo tempo ha fatto 2-0 o 0-2
      const aScoreCurrCheck =
        (scoreB.halfTime.home === 2 && scoreB.halfTime.away === 0) ||
        (scoreB.halfTime.home === 0 && scoreB.halfTime.away === 2);

      //milan precedente a primo tempo ha fatto 2-0 o 0-2
      const bScoreCurrCheck =
        (scoreA.halfTime.home === 2 && scoreA.halfTime.away === 0) ||
        (scoreA.halfTime.home === 0 && scoreA.halfTime.away === 2);

      return (
        (aScorePrevCheck && aScoreCurrCheck) ||
        (bScorePrevCheck && bScoreCurrCheck)
      );
    }

    return false;
  };

  const calculateMethod2 = (aID, bID) => {
    const aPrev = previousRoundResults.find(
      (ele) => ele.homeTeam.id === aID || ele.awayTeam.id === aID
    );
    const bPrev = previousRoundResults.find(
      (ele) => ele.awayTeam.id === bID || ele.homeTeam.id === bID
    );

    if (aPrev && bPrev) {
      const scoreA = aPrev.score; //milan
      const scoreB = bPrev.score; // roma

      //milan precedente a primo tempo ha fatto 1-1 o 0-0 o 2-2
      const aScorePrevCheck =
        (scoreA.halfTime.home === 1 && scoreA.halfTime.away === 1) ||
        (scoreA.halfTime.home === 0 && scoreA.halfTime.away === 0) ||
        (scoreA.halfTime.home === 2 && scoreA.halfTime.away === 2);

      //roma precedente a primo tempo ha fatto 1-2 o 0-0 o 2-2
      const bScorePrevCheck =
        (scoreB.halfTime.home === 1 && scoreB.halfTime.away === 1) ||
        (scoreB.halfTime.home === 0 && scoreB.halfTime.away === 0) ||
        (scoreA.halfTime.home === 2 && scoreA.halfTime.away === 2);

      //roma precedente a primo tempo ha fatto 2-1 o 1-2
      const aScoreCurrCheck =
        (scoreB.halfTime.home === 2 && scoreB.halfTime.away === 1) ||
        (scoreB.halfTime.home === 1 && scoreB.halfTime.away === 2);

      //milan precedente a primo tempo ha fatto 2-1 o 1-2
      const bScoreCurrCheck =
        (scoreA.halfTime.home === 2 && scoreA.halfTime.away === 1) ||
        (scoreA.halfTime.home === 1 && scoreA.halfTime.away === 2);

      return (
        (aScorePrevCheck && aScoreCurrCheck) ||
        (bScorePrevCheck && bScoreCurrCheck)
      );
    }

    return false;
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">
              <Typography variant="caption">O 0.5 1t</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                <Stack>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <Avatar
                      src={row.homeTeam.crest}
                      sx={{ width: "20px", height: "20px" }}
                    />
                    <Typography variant="caption">
                      {row.homeTeam.name}
                    </Typography>
                  </Stack>
                  <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
                    <Avatar
                      src={row.awayTeam.crest}
                      sx={{ width: "20px", height: "20px" }}
                    />
                    <Typography variant="caption">
                      {row.awayTeam.name}
                    </Typography>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell align="right">
                <Stack>
                  <Typography variant="caption">
                    {getDateMatch(row.utcDate)}
                  </Typography>
                  <Typography variant="caption">
                    {getTimeMatch(row.utcDate)}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="right">
                {calculateMethod1(row.homeTeam.id, row.awayTeam.id)
                  ? "SI 1"
                  : calculateMethod2(row.homeTeam.id, row.awayTeam.id)
                  ? "SI 2"
                  : "NO"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
